/** @format */
import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import axios from "../utils/axios";

const GenderChart = () => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        renderEducationChart();
    }, []);

    const renderEducationChart = () => {
        axios
            .get("/statistics/user-education")
            .then((resp) => {
                setLoaded(true);
                const education = resp.data;
                const educationLabels = education.map((edu) => edu.educationName);
                const educationData = education.map((edu) => edu.count);

                const ctx = document.getElementById("education-chart");
                return new Chart(ctx, {
                    type: "bar",
                    data: {
                        labels: educationLabels,
                        datasets: [
                            {
                                label: "Users",
                                data: educationData,
                                backgroundColor: "rgba(255, 159, 64, 0.2)",
                                borderColor: "rgba(255, 159, 64, 1)",
                                borderWidth: 1,
                            },
                        ],
                    },
                    options: {
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                },
                            ],
                        },
                    },
                });
            })
            .catch((error) => {
                console.error("renderAgeChart -> error", error);
            });
    };

    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Education
                </Typography>
                {!loaded ? (
                    <Box paddingY={6}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <div style={{ width: "100%", height: "100%" }}>
                        <canvas id="education-chart"></canvas>
                    </div>
                )}
            </CardContent>
            {/* <CardActions>
      <Button>Detail</Button>
    </CardActions> */}
        </Card>
    );
};

export default GenderChart;
