/** @format */
import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import axios from "../utils/axios";
import { Box } from "@material-ui/core";

const UserCount = () => {
	const [totalUsers, setTotalUsers] = useState(null);
	const [totalVerifiedUsers, setTotalVerifiedUsers] = useState(null);
	const [totalPremiumUsers, setTotalPremiumUsers] = useState(null);
	const [completedProfile, setCompletedProfile] = useState(null);

	useEffect(() => {
		getTotalCompletedProfile();
		getTotalUsers();
		getTotalVerifiedUsers();
		getTotalPremiumUsers();
	}, []);

	const getTotalUsers = () => {
		axios
			.get("statistics/total-users")
			.then((resp) => {
				setTotalUsers(resp.data.count);
			})
			.catch((error) => {});
	};

	const getTotalVerifiedUsers = () => {
		axios
			.get("statistics/total-verified-users")
			.then((resp) => {
				setTotalVerifiedUsers(resp.data.count);
			})
			.catch((error) => { });
	};

	const getTotalPremiumUsers = () => {
		axios
			.get("statistics/total-premium-users")
			.then((resp) => {
				setTotalPremiumUsers(resp.data.count);
			})
			.catch((error) => { });
	};

	const getTotalCompletedProfile = () => {
		axios
			.get("statistics/total-user-completed-profile")
			.then((resp) => {
				setCompletedProfile(resp.data.count);
			})
			.catch((error) => { });
	};

	return (
		<Card style={{ height: "100%" }}>
			<CardContent>
				<Typography gutterBottom variant="h5" component="h2">
					Summary
        </Typography>
				{completedProfile === null ? (
					<Box paddingY={6}>
						<CircularProgress />
					</Box>
				) : (
						<Box paddingY={2}>
							<Grid container>
								<Grid item xs>
									<Typography gutterBottom variant="h5" component="h2">
										{totalUsers}
									</Typography>
									<Typography>Total users</Typography>
								</Grid>
								<Grid item xs>
									<Typography gutterBottom variant="h5" component="h2">
										{completedProfile}
									</Typography>
									<Typography>Completed profile</Typography>
								</Grid>
								<Grid item xs>
									<Typography gutterBottom variant="h5" component="h2">
										{totalVerifiedUsers}
									</Typography>
									<Typography>Verified users</Typography>
								</Grid>
								<Grid item xs>
									<Typography gutterBottom variant="h5" component="h2">
										{totalPremiumUsers}
									</Typography>
									<Typography>Premium users</Typography>
								</Grid>
							</Grid>
						</Box>
					)}
			</CardContent>
		</Card>
	);
};

export default UserCount;
