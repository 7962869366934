/** @format */

import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import {
	List,
	Datagrid,
	TextField,
	DateField,
	ShowButton,
	EmailField,
	useNotify,
	Filter,
	SelectInput,
	useRefresh,
} from "react-admin";
import ChevronRightRounded from "@material-ui/icons/ChevronRightRounded";
import axios from "../../utils/axios";
import {
	Popover,
	Button,
	RadioGroup,
	FormControlLabel,
	Radio,
	Box,
	Grid,
} from "@material-ui/core";

const OSField = ({ source, record = {} }) => (
	<span>
		{record[source] === -1
			? "Unknow"
			: record[source] === 1
				? "Android"
				: "iOS"}
	</span>
);

const TypeField = ({ source, record = {}, types }) => (
	<span style={{ color: record[source] < 5 ? "red" : "black" }}>{`${
		record[source] < 5 ? "Technical/" : ""
		}${types[record[source]]?.label}`}</span>
);

const ActionButton = ({ record, source, types }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedStatus, setSelectedStatus] = useState(record[source] + "");

	const setStatus = useCallback(() => {
		axios
			.put(`/feedbacks/update-status`, {
				status: selectedStatus,
				id: record["id"],
			})
			.then(() => {
				notify("Success!", "info");
				refresh();
			})
			.catch(() => { });
	}, [notify, record, refresh, selectedStatus]);

	const handleOpenPopover = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClosePopover = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<Button
				onClick={handleOpenPopover}
				size="small"
				className={types[record[source]]?.toLowerCase()}
			>
				<span>
					{`${types[record[source]]?.split("_")?.join(" ")?.toLowerCase()} ${
						types[record[source]] === "DONE" ? "✓" : ""
					}`}
				</span>
			</Button>
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClosePopover}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<Box p={2}>
					<RadioGroup
						aria-label="status"
						name="status"
						value={selectedStatus}
						onChange={(e) => {
							setSelectedStatus(e.target.value);
						}}
					>
						<FormControlLabel
							disabled={record[source] === 1 || record[source] === 3}
							value="1"
							control={<Radio />}
							label="New"
						/>
						<FormControlLabel
							disabled={record[source] === 2 || record[source] === 3}
							value="2"
							control={<Radio />}
							label="In progress"
						/>
						<FormControlLabel
							disabled={record[source] === 3}
							value="3"
							control={<Radio />}
							label="Done"
						/>
						<FormControlLabel
							disabled
							value="4"
							control={<Radio />}
							label="Blocked"
						/>
						<FormControlLabel
							disabled
							// disabled={record[source] !== 3}
							value="5"
							control={<Radio />}
							label="Re-open"
						/>
					</RadioGroup>
					<Grid container spacing={2} style={{ marginTop: 16 }}>
						<Grid item xs={6} onClick={setStatus}>
							<Button color="secondary" variant="contained">
								Apply
              				</Button>
						</Grid>
						<Grid item xs={6}>
							<Button onClick={handleClosePopover}>Cancel</Button>
						</Grid>
					</Grid>
				</Box>
			</Popover>
		</React.Fragment>
	);
};

const FeedbackFilters = (props) => {
	return (
		<Filter {...props}>
			<SelectInput
				source="status"
				choices={[
					{ id: 1, name: "New" },
					{ id: 2, name: "In Progress" },
					{ id: 3, name: "Done" },
				]}
			/>
			<SelectInput
				source="type_id"
				choices={[
					{ id: 1, name: "Technical issue/Trouble with account login" },
					{ id: 2, name: "Technical issue/A feature is not working" },
					{ id: 3, name: "Technical issue/Help with billing or payment" },
					{ id: 3, name: "Technical issue/Something else" },
					{ id: 3, name: "Suggest an idea" },
					{ id: 3, name: "Safety/Privacy issue" },
					{ id: 3, name: "Somethings else" },
				]}
			/>
		</Filter>
	);
};

const UserFeedbackList = (props) => {
	const [types, setTypes] = useState();
	console.log("UserFeedbackList -> types", types);

	useEffect(() => {
		const getData = async () => {
			try {
				const resp = await Axios.get(
					"https://staging-api.toast.dating/v1/feedback/resources"
				);
				setTypes(resp.data);
			} catch (error) {
				console.error("UserFeedbackList -> error", error);
			}
		};
		getData();
	}, []);

	return (
		<List
			{...props}
			perPage={25}
			filters={<FeedbackFilters />}
			title="Feedback List"
			bulkActionButtons={null}
		>
			<Datagrid>
				{/* <TextField la.bel="Id" source="id" /> */}
				<TextField label="User's id" source="userId" />
				<EmailField label="Email" source="emailContact" />
				<TypeField
					label="Type"
					source="typeId"
					types={types?.feedbackTypeReverseMapping || []}
				/>
				<OSField label="OS" source="os" />
				<DateField label="Received date" source="createdAt" />
				<DateField label="Last update" source="updatedAt" />
				<ActionButton
					label="Status"
					source="status"
					types={types?.feedbackStatusMapping || []}
				/>
				<ShowButton
					alignIcon="right"
					icon={<ChevronRightRounded />}
					label="Detail"
				/>
			</Datagrid>
		</List>
	);
};

export default UserFeedbackList;