/** @format */
import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import axios from "../utils/axios";

const GenderChart = () => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		renderAgeChart();
	}, []);

	const renderAgeChart = () => {
		axios
			.get("/statistics/user-age")
			.then((resp) => {
				setLoaded(true);
				const ages = resp.data;
				let under18 = 0;
				let from18to25 = 0;
				let from26to35 = 0;
				let from36to45 = 0;
				let from46to55 = 0;
				let from56to65 = 0;
				let over65 = 0;
				ages.forEach((age) => {
					if (age.age < 18) {
						under18 += age.count;
					} else if (age.age < 26) {
						from18to25 += age.count;
					} else if (age.age < 36) {
						from26to35 += age.count;
					} else if (age.age < 46) {
						from36to45 += age.count;
					} else if (age.age < 56) {
						from46to55 += age.count;
					} else if (age.age < 66) {
						from56to65 += age.count;
					} else {
						over65 += age.count;
					}
				});

				const ctx = document.getElementById("age-chart");
				return new Chart(ctx, {
					type: "bar",
					data: {
						labels: ["<18", "18-25", "26-35", "36-45", "46-55", "56-65", ">65"],
						datasets: [
							{
								label: "Users",
								data: [
									under18,
									from18to25,
									from26to35,
									from36to45,
									from46to55,
									from56to65,
									over65,
								],
								backgroundColor: "rgba(75, 192, 192, 0.2)",
								borderColor: "rgba(75, 192, 192, 1)",
								borderWidth: 1,
							},
						],
					},
					options: {
						scales: {
							yAxes: [
								{
									ticks: {
										beginAtZero: true,
									},
								},
							],
						},
					},
				});
			})
			.catch((error) => {
				console.error("renderAgeChart -> error", error);
			});
	};

	return (
		<Card>
			<CardContent>
				<Typography gutterBottom variant="h5" component="h2">
					Age
        		</Typography>
				{!loaded ? (
					<Box paddingY={6}>
						<CircularProgress />
					</Box>
				) : (
					<div style={{ width: "100%", height: "100%" }}>
						<canvas id="age-chart"></canvas>
					</div>
				)}
			</CardContent>
			{/* <CardActions>
      <Button>Detail</Button>
    </CardActions> */}
		</Card>
	);
};

export default GenderChart;
