/** @format */

import React from "react";
import { Admin, Resource } from "react-admin";
import ReportProblemRounded from "@material-ui/icons/ReportProblemRounded";
import SupervisorAccountRounded from "@material-ui/icons/SupervisorAccountRounded";
import VerifiedUserRounded from "@material-ui/icons/VerifiedUserRounded";
import FeedbackRounded from "@material-ui/icons/FeedbackRounded";
import authProvider from "./providers/auth";
import restProvider from "./providers/data";
import UserList from "./model/users/list";
import VerificationList from "./model/users/verification-list";
import UserShow from "./model/users/show";
import CallReportList from "./model/call/call-reports-list";
import { theme } from "./theme";
import Dashboard from "./Dashboard";
import LoginPage from "./LoginPage";
import FeedbackList from "./model/feedback/list";
import FeedbackShow from "./model/feedback/show";
import "./App.css";

function App() {
	return (
		<Admin
			theme={theme}
			loginPage={LoginPage}
			dashboard={Dashboard}
			dataProvider={restProvider}
			authProvider={authProvider}
		>
			<Resource
				icon={SupervisorAccountRounded}
				name="users"
				list={UserList}
				show={UserShow}
			/>
			<Resource
				icon={VerifiedUserRounded}
				options={{ label: "Verification Request" }}
				name="verification"
				list={VerificationList}
			/>
			<Resource
				icon={ReportProblemRounded}
				options={{ label: "Call Reports" }}
				name="call-reports"
				list={CallReportList}
			/>
			<Resource
				icon={FeedbackRounded}
				options={{ label: "User Feedback" }}
				name="user-feedback"
				list={FeedbackList}
				show={FeedbackShow}
			/>
		</Admin>
	);
}

export default App;
