/** @format */
import React from "react";
import Grid from "@material-ui/core/Grid";
import GenderChart from "./components/GenderChart";
import AgeChart from "./components/AgeChart";
import UserCount from "./components/UserCount";
import EducationChart from "./components/EducationChart";
import RelationChart from "./components/RelationChart";
import LookingForCommitmentChart from "./components/LookingForCommitmentChart";
import LookingForConnectionChart from "./components/LookingForConnectionChart";

const Dashboard = () => {
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        paddingTop: "20px",
      }}
    >
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="stretch"
        justify="center"
      >
        <Grid item xs={12} md={12}>
          <UserCount />
        </Grid>
        <Grid item xs={12} md={4}>
          <GenderChart />
        </Grid>
        <Grid item xs={12} md={4}>
          <LookingForCommitmentChart />
        </Grid>
        <Grid item xs={12} md={4}>
          <LookingForConnectionChart />
        </Grid>
        <Grid item xs={12} md={4}>
          <AgeChart />
        </Grid>
        <Grid item xs={12} md={4}>
          <EducationChart />
        </Grid>
        <Grid item xs={12} md={4}>
          <RelationChart />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
