import axios from '../utils/axios'

const authProvider = {
	login: ({ username, password }) => {
		return axios.post('/account/login', { username, password }).then(result => {
			localStorage.setItem('token', result.data.accessToken)
		}).catch(error => {
			if (error.response) {
				throw error.response
			} else if (error.request) {
				throw error.request
			} else {
				// Something happened in setting up the request that triggered an Error
				throw error.message
			}
		});
	},
	logout: () => {
		localStorage.removeItem('token');
		return Promise.resolve();
	},
	checkAuth: () => localStorage.getItem('token')
		? Promise.resolve()
		: Promise.reject(),
	checkError: error => {
		const status = error.status;
		if (status === 401 || status === 403) {
			localStorage.removeItem('token');
			return Promise.reject();
		}
		return Promise.resolve();
	},
	getPermissions: params => Promise.resolve(),
};

export default authProvider
