/** @format */
import React from "react";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import {
	Grid, 
	Typography,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableHead,
	Paper,
	TableBody
} from '@material-ui/core';

const useStyles = makeStyles({
	table: {
	  minWidth: 650,
	},
});

const getDate = (timestamp)=>{
	if(timestamp === undefined || !timestamp) {
		return "No record";
	}
	if(timestamp === null) {
		return "No record";
	}
	if(timestamp === 0) {
		return "No record";
	}
    return moment(timestamp).format("MM-DD-YYYY HH:mm:ss");
}

const MatchingUserDetail = (props) => {
	const classes = useStyles();
	const item = props.item;
	if(item === undefined || !item) {
		return (
			<Typography>
				No any data
			</Typography>
		)
	}
	if(item.points === undefined || !item.points) {
		return (
			<Typography>
				No any data
			</Typography>
		)
	}
	const points = {...item.points};
	return (
		<React.Fragment>
			<Grid container>
				<Grid item xs={12}>
					<TableContainer component={Paper}>
						<Table className={classes.table} size="small" aria-label="a dense table">
							<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Reason</TableCell>
								<TableCell align="right">Score</TableCell>
							</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell component="th" scope="row">
										<b>Universal temporary bonus</b>
									</TableCell>
									<TableCell>
									</TableCell>
									<TableCell align="right">
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										New account bonus 
									</TableCell>
									<TableCell>
										{points.activityreason && points.newaccountreason.createdat ? getDate(points.newaccountreason.createdat) : "No Record"}
									</TableCell>
									<TableCell align="right">
										{points.newaccountscore ? points.newaccountscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Activity bonus 
									</TableCell>
									<TableCell>
										{points.activityreason && points.activityreason.activityat ? getDate(points.activityreason.activityat) : "No Record"}
									</TableCell>
									<TableCell align="right">
										{points.activityscore ? points.activityscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Inactivity 
									</TableCell>
									<TableCell>
										{points.inactivityreason && points.inactivityreason.inactivityat ? getDate(points.inactivityreason.inactivityat) : "No Record"}
									</TableCell>
									<TableCell align="right">
										{points.inactivescore ? points.inactivescore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Per sending Like 
									</TableCell>
									<TableCell>
										{points.sentlikereason && points.sentlikereason.likedin14daysList && (points.sentlikereason.likedin14daysList).length > 0 ? points.sentlikereason.likedin14daysList.map((likedin14day, key) => {
											return (
												<p key={key}>{key + 1} - {getDate(likedin14day)}</p>
											)
										}) : "No Record"}
									</TableCell>
									<TableCell align="right">
										{points.sentlikescore ? points.sentlikescore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Per sending Quick call invite
									</TableCell>
									<TableCell>
										{points.quickcallinviationreason && points.quickcallinviationreason.quickcallinviationin14daysList && (points.quickcallinviationreason.quickcallinviationin14daysList).length > 0 ? points.quickcallinviationreason.quickcallinviationin14daysList.map((quickcallinviation, key) => {
											return (
												<p key={key}>{key + 1} - {getDate(quickcallinviation)}</p>
											)
										}) : "No Record"}
									</TableCell>
									<TableCell align="right">
										{points.quickcallinviationscore ? points.quickcallinviationscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Per Match
									</TableCell>
									<TableCell>
										{points.matchedreason && points.matchedreason.matchedin14daysList && (points.matchedreason.matchedin14daysList).length > 0 ? points.matchedreason.matchedin14daysList.map((matched, key) => {
											return (
												<p key={key}>{key + 1} - {getDate(matched)}</p>
											)
										}) : "No Record"}
									</TableCell>
									<TableCell align="right">
										{points.matchedscore ? points.matchedscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Per Connection (positiv call)
									</TableCell>
									<TableCell>
										{points.positivecalledreason && points.positivecalledreason.positivecalledin14daysList && (points.positivecalledreason.positivecalledin14daysList).length > 0 ? points.positivecalledreason.positivecalledin14daysList.map((positivecalled, key) => {
											return (
												<p key={key}>{key + 1} - {getDate(positivecalled)}</p>
											)
										}) : "No Record"}
									</TableCell>
									<TableCell align="right">
										{points.positivecalledscore ? points.positivecalledscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										<b>Relative temporary penalty</b>
									</TableCell>
									<TableCell>
									</TableCell>
									<TableCell align="right">
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Not interacted (per user)
									</TableCell>
									<TableCell>
										{points.notinterestedreason && points.notinterestedreason.notinterestedupdatedat ? getDate(points.notinterestedreason.notinterestedupdatedat) : "No Record"}
									</TableCell>
									<TableCell align="right">
										{points.notinterestedscore ? points.notinterestedscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										<b>Universal temporary penalty</b>
									</TableCell>
									<TableCell>
									</TableCell>
									<TableCell align="right">
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Missed scheduled call
									</TableCell>
									<TableCell>
										{points.missedscheduledcallreason && points.missedscheduledcallreason.missedscheduledcallin14daysList && (points.missedscheduledcallreason.missedscheduledcallin14daysList).length > 0 ? points.missedscheduledcallreason.missedscheduledcallin14daysList.map((missedscheduledcall, key) => {
											return (
												<p key={key}>{key + 1} - {getDate(missedscheduledcall)}</p>
											)
										}) : "No Record"}
									</TableCell>
									<TableCell align="right">
										{points.missedscheduledcallscore ? points.missedscheduledcallscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Missed accepted call
									</TableCell>
									<TableCell>
										{points.missedacceptedcallreason && points.missedacceptedcallreason.missedacceptedcallin14daysList && (points.missedacceptedcallreason.missedacceptedcallin14daysList).length > 0 ? points.missedacceptedcallreason.missedacceptedcallin14daysList.map((missedacceptedcall, key) => {
											return (
												<p key={key}>{key + 1} - {getDate(missedacceptedcall)}</p>
											)
										}) : "No Record"}
									</TableCell>
									<TableCell align="right">
										{points.missedacceptedcallscore ? points.missedacceptedcallscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Call rejection (negativ call)
									</TableCell>
									<TableCell>
										{points.missedrejectedcallreason && points.missedrejectedcallreason.missedrejectedcallin14daysList && (points.missedrejectedcallreason.missedrejectedcallin14daysList).length > 0 ? points.missedrejectedcallreason.missedrejectedcallin14daysList.map((missedrejectedcall, key) => {
											return (
												<p key={key}>{key + 1} - {getDate(missedrejectedcall)}</p>
											)
										}) : "No Record"}
									</TableCell>
									<TableCell align="right">
										{points.missedrejectedcallscore ? points.missedrejectedcallscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										<b>Universal Permanent bonus</b>
									</TableCell>
									<TableCell>
									</TableCell>
									<TableCell align="right">
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Image verification
									</TableCell>
									<TableCell>
										{points.verificationimagereason && points.verificationimagereason.hasverificationimage ? "Yes" : "No"}
									</TableCell>
									<TableCell align="right">
										{points.verificationimagescore ? points.verificationimagescore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Per Picture in Profile
									</TableCell>
									<TableCell>
										{points.profilephotosreason && points.profilephotosreason.noprofilephotos ? points.profilephotosreason.noprofilephotos : 0}
									</TableCell>
									<TableCell align="right">
										{points.profilephotosscore ? points.profilephotosscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Show Relationship status
									</TableCell>
									<TableCell>
										{points.showrelationshipstatusreason && points.showrelationshipstatusreason.showrelationshipstatus ? "Yes" : "No"}
									</TableCell>
									<TableCell align="right">
										{points.showrelationshipstatusscore ? points.showrelationshipstatusscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Show height
									</TableCell>
									<TableCell>
										{points.showheightreason && points.showheightreason.showheight ? "Yes" : "No"}
									</TableCell>
									<TableCell align="right">
										{points.showheightscore ? points.showheightscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Education
									</TableCell>
									<TableCell>
										{points.showeducationreason && points.showeducationreason.showeducation ? "Yes" : "No"}
									</TableCell>
									<TableCell align="right">
										{points.showeducationscore ? points.showeducationscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										As a kid I dreamt of
									</TableCell>
									<TableCell>
										{points.showdreamaskidreason && points.showdreamaskidreason.showdreamaskid ? "Yes" : "No"}
									</TableCell>
									<TableCell align="right">
										{points.showdreamaskidscore ? points.showdreamaskidscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										STD Check
									</TableCell>
									<TableCell>
										{points.showstdreason && points.showstdreason.showstd ? "Yes" : "No"}
									</TableCell>
									<TableCell align="right">
										{points.showstdscore ? points.showstdscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										<b>Calcutlated between potentials</b>
									</TableCell>
									<TableCell>
									</TableCell>
									<TableCell align="right">
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Per overlapping commitment 
									</TableCell>
									<TableCell>
										{points.overlappingcommitmentreason && points.overlappingcommitmentreason.nocommitmentoverlapping ? points.overlappingcommitmentreason.nocommitmentoverlapping : 0}
									</TableCell>
									<TableCell align="right">
										{points.overlappingcommitmentscore ? points.overlappingcommitmentscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Per Age interest
									</TableCell>
									<TableCell>
										{points.overlappingagesreason && points.overlappingagesreason.noagesoverlapping ? points.overlappingagesreason.noagesoverlapping : 0}
									</TableCell>
									<TableCell align="right">
										{points.overlappingagesscore ? points.overlappingagesscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Liked by other user
									</TableCell>
									<TableCell>
										{points.likedbyreason && points.likedbyreason.likedby ? "Yes" : "No"}
									</TableCell>
									<TableCell align="right">
										{points.likedbyscore ? points.likedbyscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Per overlapping sexual orientation
									</TableCell>
									<TableCell>
										{points.overlappingsexualorientationsreason && points.overlappingsexualorientationsreason.nosexualorientationsoverlapping ? points.overlappingsexualorientationsreason.nosexualorientationsoverlapping : 0}
									</TableCell>
									<TableCell align="right">
										{points.overlappingsexualorientationsscore ? points.overlappingsexualorientationsscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										<b>Week Bonus</b>
									</TableCell>
									<TableCell>
									</TableCell>
									<TableCell align="right">
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										Bonus every 3 weeks 
									</TableCell>
									<TableCell>
										{points.threeweekbonusreason && points.threeweekbonusreason.noofweeks ? points.threeweekbonusreason.noofweeks : 0}
									</TableCell>
									<TableCell align="right">
										{points.threeweekbonusscore ? points.threeweekbonusscore : 0}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">
										<b>Total</b>
									</TableCell>
									<TableCell>
									</TableCell>
									<TableCell align="right">
										{points.total ? points.total : 0}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default MatchingUserDetail;
