/** @format */
import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
	palette: {
		primary: {
			main: 'rgb(255, 152, 24)',
		},
		secondary: {
			main: 'rgb(52, 100, 214)',
		},
		error: {
			main: 'rgb(253, 29, 62)',
		},
		warning: {
			main: 'rgb(255, 152, 24)',
		},
		success: {
			main: 'rgb(1, 203, 145)',
		},
		text: {
			primary: 'rgb(71, 68, 84)',
			secondary: 'rgb(158, 155, 176)',
			disabled: 'rgb(213, 212, 221)',
		},
		contrastThreshold: 3,
		tonalOffset: 0.2,
	},
	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'Quicksand',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Arial',
			'sans-serif',
		].join(','),
	},
	overrides: {
		MuiButton: {
			// override the styles of all instances of this component
			root: {
				// Name of the rule
				color: 'white', // Some CSS
			},
		},
	},
	shadows: [
		'none',
		'0 4px 8px 0 rgba(0, 0, 0, 0.08)',
		'0 4px 8px 0 rgba(0, 0, 0, 0.08)',
		'0 4px 8px 0 rgba(0, 0, 0, 0.08)',
		'0 4px 8px 0 rgba(0, 0, 0, 0.08)',
		'0 4px 8px 0 rgba(0, 0, 0, 0.08)',
		'0 4px 8px 0 rgba(0, 0, 0, 0.08)',
		'0 4px 8px 0 rgba(0, 0, 0, 0.08)',
		'0 4px 8px 0 rgba(0, 0, 0, 0.08)',
		'0 4px 8px 0 rgba(0, 0, 0, 0.08)',
		'0 4px 8px 0 rgba(0, 0, 0, 0.08)',
	],
	shape: {
		borderRadius: 10,
	},
});
