/** @format */

import axios from "../utils/axios";

const resourceMappings = {
	users: "users",
	verification: "users",
	"call-reports": "call-reports",
	"user-feedback": "feedbacks",
};

export default {
	getList: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		let query = {
			sort_by: `${order.toLowerCase()}(${field
				.replace(/([A-Z])/g, "_$1")
				.toLowerCase()})`,
			page,
			per_page: perPage,
			...params.filter,
			// filter: JSON.stringify(params.filter),
		};

		if (resource === "verification") {
			query = {
				...query,
				image_verified: false,
				image_verification_uploaded: true,
			};
		}

		return axios
			.get(`/${resourceMappings[resource]}`, {
				params: query,
			})
			.then((response) => {
				return {
					data: response.data.data,
					total: response.data.totalCount,
				};
			}).catch((error) => {
				return {
					data: [],
					total: 0,
				};
			});
	},
	getOne: (resource, params) => {
		return axios
			.get(`/${resourceMappings[resource]}/${params.id}`)
			.then((response) => {
				return {
					data: response.data,
				};
			}).catch((error) => {
				return {
					data: {}
				};
			});
	},

	// TODO: rest of data provider
	getMany: (resource, params) =>
		Promise.resolve({
			data: [],
		}),

	getManyReference: (resource, params) => Promise.resolve(),

	update: (resource, params) => Promise.resolve(),

	updateMany: (resource, params) => Promise.resolve(),

	create: (resource, params) => Promise.resolve(),

	delete: (resource, params) => {
		return axios
			.delete(`/${resourceMappings[resource]}/${params.id}`)
			.then(() => {
				return {
					data: [],
				};
			});
	},

	deleteMany: (resource, params) => {
		return axios
			.delete(`/${resourceMappings[resource]}/delete`, {
				data: {
					ids: params.ids
				}
			})
			.then(() => {
				return {
					data: [],
				};
			});
	},
};
