/** @format */
import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import axios from "../utils/axios";

const RelationChart = () => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		renderRelationChart();
	}, []);

	const renderRelationChart = () => {
		axios
			.get("/statistics/user-relationship-status")
			.then((resp) => {
				setLoaded(true);
				const relation = resp.data;
				const relationLabels = relation.map((edu) => edu.relationshipStatus);
				const relationData = relation.map((edu) => edu.count);

				const ctx = document.getElementById("relation-chart");
				return new Chart(ctx, {
					type: "bar",
					data: {
						labels: relationLabels,
						datasets: [
							{
								label: "Users",
								data: relationData,
								backgroundColor: "rgba(255, 99, 132, 0.2)",
								borderColor: "rgba(255, 99, 132, 1)",
								borderWidth: 1,
							},
						],
					},
					options: {
						scales: {
							yAxes: [
								{
									ticks: {
										beginAtZero: true,
									},
								},
							],
						},
					},
				});
			})
			.catch((error) => {
				console.error("renderAgeChart -> error", error);
			});
	};

	return (
		<Card>
			<CardContent>
				<Typography gutterBottom variant="h5" component="h2">
					Relationship Status
        		</Typography>
				{!loaded ? (
					<Box paddingY={6}>
						<CircularProgress />
					</Box>
				) : (
						<div style={{ width: "100%", height: "100%" }}>
							<canvas id="relation-chart"></canvas>
						</div>
					)}
			</CardContent>
			{/* <CardActions>
      <Button>Detail</Button>
    </CardActions> */}
		</Card>
	);
};

export default RelationChart;
