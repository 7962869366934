import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import {
	Show,
	SimpleShowLayout,
	TextField,
	EmailField,
	DateField,
	useNotify,
	useRefresh
} from "react-admin";
import {
	Popover,
	Button,
	RadioGroup,
	FormControlLabel,
	Radio,
	Box,
	Grid
} from "@material-ui/core";
import ChevronLeftRounded from "@material-ui/icons/ChevronLeftRounded";
import axios from "../../utils/axios";

const OSField = ({ source, record = {} }) => (
	<div className="MuiFormControl-root MuiFormControl-marginDense">
		<label
			className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-336 MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense"
			data-shrink="true"
		>
			<span>OS</span>
		</label>
		<div className="MuiTypography-root MuiTypography-body2">
			{record[source] === -1
				? "Unknow"
				: record[source] === 1
					? "Android"
					: "iOS"}
		</div>
	</div>
);

const ActionButton = ({ record, source, types }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedStatus, setSelectedStatus] = useState(record[source] + "");

	const setStatus = useCallback(() => {
		axios
			.put(`/feedbacks/update-status`, {
				status: selectedStatus,
				id: record["id"]
			})
			.then(() => {
				notify("Success!", "info");
				refresh();
			})
			.catch(() => { });
	}, [notify, record, refresh, selectedStatus]);

	const handleOpenPopover = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClosePopover = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<Button
				onClick={handleOpenPopover}
				size="small"
				className={types[record[source]]?.toLowerCase()}
			>
				<span>
					{`${types[record[source]]
						?.split("_")
							?.join(" ")
								?.toLowerCase()} ${types[record[source]] === "DONE" ? "✓" : ""}`}
				</span>
			</Button>
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClosePopover}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				<Box p={2}>
					<RadioGroup
						aria-label="status"
						name="status"
						value={selectedStatus}
						onChange={e => {
							setSelectedStatus(e.target.value);
						}}
					>
						<FormControlLabel
							disabled={record[source] === 1 || record[source] === 3}
							value="1"
							control={<Radio />}
							label="New"
						/>
						<FormControlLabel
							disabled={record[source] === 2 || record[source] === 3}
							value="2"
							control={<Radio />}
							label="In progress"
						/>
						<FormControlLabel
							disabled={record[source] === 3}
							value="3"
							control={<Radio />}
							label="Done"
						/>
						<FormControlLabel
							disabled
							value="4"
							control={<Radio />}
							label="Blocked"
						/>
						<FormControlLabel
							disabled
							// disabled={record[source] !== 3}
							value="5"
							control={<Radio />}
							label="Re-open"
						/>
					</RadioGroup>
					<Grid container spacing={2} style={{ marginTop: 16 }}>
						<Grid item xs={6} onClick={setStatus}>
							<Button color="secondary" variant="contained">
								Apply
              </Button>
						</Grid>
						<Grid item xs={6}>
							<Button onClick={handleClosePopover}>Cancel</Button>
						</Grid>
					</Grid>
				</Box>
			</Popover>
		</React.Fragment>
	);
};

const TypeField = ({ source, record = {}, types }) => (
	<div className="MuiFormControl-root MuiFormControl-marginDense">
		<label
			className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-336 MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense"
			data-shrink="true"
		>
			<span>Type</span>
		</label>
		<span className="MuiTypography-root MuiTypography-body2">
			{types[record[source]]?.label}
		</span>
	</div>
);

const Attachments = ({ record = {} }) => {
	const attachments = record.attachments;

	if (!attachments) return null;
	return (
		<div className="MuiFormControl-root MuiFormControl-marginDense">
			<label
				className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-336 MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense"
				data-shrink="true"
			>
				<span>Attachments</span>
			</label>
			<Box mt={1}>
				<Grid container spacing={2}>
					{attachments.map(image => (
						<Grid item xs={12} sm={6} md={3}>
							<img
								src={`https://instadating.blob.core.windows.net/insta-c1/${record.userId}/user-feedback-attachments/${image.name}`}
								alt={image.name}
								width="100%"
							/>
						</Grid>
					))}
				</Grid>
			</Box>
		</div>
	);
};

const FeedbackShow = props => {
	const [types, setTypes] = useState();

	useEffect(() => {
		const getData = async () => {
			try {
				const resp = await Axios.get(
					"https://staging-api.toast.dating/v1/feedback/resources"
				);
				setTypes(resp.data);
			} catch (error) {
				console.error("UserFeedbackList -> error", error);
			}
		};
		getData();
	}, []);

	return (
		<Show {...props} title="User Feedback">
			<SimpleShowLayout>
				<Box mb={2}>
					<Button
						color="primary"
						variant="outlined"
						startIcon={<ChevronLeftRounded />}
						onClick={() => {
							window.location.href = "/#/user-feedback";
						}}
					>
						Back to list
         			</Button>
				</Box>
				<TextField label="Feedback ID" source="id" />
				<TextField label="User ID" source="userId" />
				<DateField label="Created date" source="createdAt" showTime />
				<DateField label="Updated date" source="updatedAt" showTime />
				<ActionButton
					label="Status"
					source="status"
					types={types?.feedbackStatusMapping || []}
				/>
				<TypeField
					label="Type"
					source="typeId"
					types={types?.feedbackTypeReverseMapping || []}
				/>
				<EmailField label="Email" source="emailContact" />
				<OSField label="OS" source="os" />
				<Attachments />
				<TextField source="content" />
			</SimpleShowLayout>
		</Show>
	);
};

export default FeedbackShow;
