/** @format */
import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import axios from "../utils/axios";

const GenderChart = () => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		renderGenderChart();
	}, []);

	const renderGenderChart = () => {
		axios
			.get("/statistics/user-gender-distribution")
			.then((resp) => {
				setLoaded(true);
				const genders = resp.data;
				const femaleCount = genders.find(
					(gender) => gender.genderName === "Female"
				).count;
				const maleCount = genders.find((gender) => gender.genderName === "Male")
					.count;
				let otherGenderCount = 0;
				genders.forEach((gender) => {
					if (gender.genderName !== "Female" && gender.genderName !== "Male") {
						otherGenderCount = otherGenderCount + gender.count;
					}
				});
				const ctx = document.getElementById("gender-chart");
				return new Chart(ctx, {
					type: "doughnut",
					data: {
						labels: ["Female", "Male", "Others"],
						datasets: [
							{
								label: "Users",
								data: [femaleCount, maleCount, otherGenderCount],
								backgroundColor: [
									"rgba(255, 99, 132, 0.2)",
									"rgba(54, 162, 235, 0.2)",
									"rgba(153, 102, 255, 0.2)",
								],
								borderColor: [
									"rgba(255, 99, 132, 1)",
									"rgba(54, 162, 235, 1)",
									"rgba(153, 102, 255, 1)",
								],
								borderWidth: 1,
							},
						],
					},
				});
			})
			.catch((error) => {
				console.error("renderGenderChart -> error", error);
			});
	};

	return (
		<Card>
			<CardContent>
				<Typography gutterBottom variant="h5" component="h2">
					Genders
        </Typography>
				{!loaded ? (
					<Box paddingY={6}>
						<CircularProgress />
					</Box>
				) : (
					<div style={{ width: "100%", height: "100%" }}>
						<canvas id="gender-chart"></canvas>
					</div>
				)}
			</CardContent>
			{/* <CardActions>
      <Button>Detail</Button>
    </CardActions> */}
		</Card>
	);
};

export default GenderChart;
