/** @format */

import React from 'react';
import { List, Datagrid, TextField, DateField, Filter, TextInput } from 'react-admin';

const ReportTypeField = ({ source, record = {} }) => (
	<span style={{ textTransform: 'capitalize' }}>
		{record[source].split('_').join(' ')}
	</span>
);

const ReportFilter = (props) => {
	return (
		<Filter {...props}>
			<TextInput label="Reporter Id" source="reporter_id" alwaysOn />
			<TextInput label="Reported Id" source="user_id" alwaysOn />
		</Filter>
	);
};

const CallReportList = (props) => {
	return (
		<List {...props} filters={<ReportFilter />}  title='Call Report List' sort={{ field: 'created_at', order: 'DESC' }}>
			<Datagrid>
				<DateField showTime label='Report Date' source='createdAt'/>
				<TextField
					sortable={false}
					label='Reported Phone'
					source='reportedPerson.phone'
				/>
				<TextField
					sortable={false}
					label='Reported ID'
					source='reportedPerson.id'
				/>
				<ReportTypeField
					sortable={false}
					label='Report Type'
					source='reportType'
				/>
				<TextField
					sortable={false}
					label='Reporter Phone'
					source='reporter.phone'
				/>
				<TextField sortable={false} label='Reporter ID' source='reporter.id' />
				<TextField
					sortable={false}
					label='Reason'
					source='reason'
					cellClassName='call-reports__reason'
				/>
			</Datagrid>
		</List>
	);
};

export default CallReportList;
