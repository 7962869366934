import axios from "axios";

const getAuthorizationHeader = () => {
	const token = localStorage.getItem("token");
	if (!token) return null;
	else return `Bearer ${token}`;
};

const instance = axios.create({
	baseURL: `${window._env_.REACT_APP_SERVER_URL}/v1/admin`,
	headers: {
		Accept: "application/json",
		Authorization: getAuthorizationHeader(),
	},
});

instance.interceptors.request.use((config) => {
	return {
		...config,
		headers: {
			...config.headers,
			Authorization: getAuthorizationHeader(),
		},
	};
});

instance.interceptors.response.use((response) => {
	if (response.status === 401) {
		window.location = '/#/login';
		return;
    }
    return response;
}, function (error) {
	if(error.response.status === 401) {
		window.location = '/#/login';
		return;
	}
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	return Promise.reject(error);
});

export default instance;
