/** @format */
import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import axios from "../utils/axios";

const LookingForCommitmentChart = () => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		renderLookingForCommitmentChart();
	}, []);

	const renderLookingForCommitmentChart = () => {
		axios
			.get("/statistics/user-looking-for-commitment")
			.then(resp => {
				setLoaded(true);
				const commitment = resp.data;
				const commitmentLabels = commitment.map(edu => edu.commitmentName);
				const commitmentData = commitment.map(edu => edu.count);

				const ctx = document.getElementById("commitment-chart");
				return new Chart(ctx, {
					type: "bar",
					data: {
						labels: commitmentLabels,
						datasets: [
							{
								label: "Users",
								data: commitmentData,
								backgroundColor: [
									"rgba(255, 99, 132, 0.2)",
									"rgba(54, 162, 235, 0.2)",
									"rgba(255, 206, 86, 0.2)",
									"rgba(75, 192, 192, 0.2)",
									"rgba(153, 102, 255, 0.2)",
									"rgba(255, 159, 64, 0.2)"
								],
								borderColor: [
									"rgba(255, 99, 132, 1)",
									"rgba(54, 162, 235, 1)",
									"rgba(255, 206, 86, 1)",
									"rgba(75, 192, 192, 1)",
									"rgba(153, 102, 255, 1)",
									"rgba(255, 159, 64, 1)"
								],
								borderWidth: 1
							}
						]
					},
					options: {
						scales: {
							yAxes: [
								{
									ticks: {
										beginAtZero: true
									}
								}
							]
						}
					}
				});
			})
			.catch(error => {
				console.error("renderAgeChart -> error", error);
			});
	};

	return (
		<Card>
			<CardContent>
				<Typography gutterBottom variant="h5" component="h2">
					What commitment user looking for:
        		</Typography>
				{!loaded ? (
					<Box paddingY={6}>
						<CircularProgress />
					</Box>
				) : (
					<div style={{ width: "100%", height: "100%" }}>
						<canvas id="commitment-chart"></canvas>
					</div>
				)}
			</CardContent>
			{/* <CardActions>
      <Button>Detail</Button>
    </CardActions> */}
		</Card>
	);
};

export default LookingForCommitmentChart;
